<template>
  <v-dialog v-model="dialog" max-width="60%" @click:outside="$emit('update:dialog', false);" @input="dialogChanged">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
    >
      <v-card>
        <v-card-title class="primary--text px-4 py-1 text-body-1">
          <v-spacer />
          <v-btn
            class="mx-1"
            color="primary"
            small
            @click="saveSupplied"
            :disabled="!valid || model.status === 2 || supplied.length <= 0"
          >
            {{ $t('delivery.save') }}
          </v-btn>
          <v-btn
            icon
            @click="$emit('update:dialog', false);"
          >
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-simple-table
          dense
          class="custom-table item-table"
          fixed-header
          height="250"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th :style="{ width: '40px' }">
                  {{ $t("quotation.lineNumber") }}
                </th>
                <th :style="{ width: '240px' }">
                  {{ $t("quotation.itemDescription") }}
                </th>
                <th :style="{ width: '50px' }">
                  {{ $t("quotation.quantity") }}
                </th>
                <th :style="{ width: '50px' }">
                  {{ $t("quotation.suppliedQty") }}
                </th>
                <th :style="{ width: '50px' }">
                  {{ $t("quotation.remainingQty") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="supplied.length <= 0">
                <td colspan="5" class="text-center">{{$t('common.noDataFound')}}</td>
              </tr>
              <template v-else v-for="(item, index) in supplied">
                <tr v-if="item.item_code !== '*'" :key="index">
                  <td rowspan="1"><span>{{ item.line_number }}</span></td>
                  <td><span>{{ item.item_description }}</span></td>
                  <td><span>{{ item.quantity ? item.quantity : 0 }}</span></td>
                  <td>
                    <v-text-field
                      :disabled="model.status === 2"
                      dense
                      hide-details="auto"
                      required
                      :rules="[v => ((v && (item.quantity >= Number(v)) && (Number(v) >= 0))) || $t('formRules.suppliedQty')]"
                      v-model="item.supplied"
                    />
                  </td>
                  <td><span>{{ item.remainingQuantity ? item.remainingQuantity : 0 }}</span></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "RemainingSuppliedDialog",
  props: ["dialog", "supplied", "doc", "docId", "model"],
  data() {
    return {
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      accessRight: 'accessRight',
    })
  },
  mounted() {
    this.shouldUpdateQty();
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.shouldUpdateQty();
      }
    }
  },
  methods: {
    async shouldUpdateQty() {
      if (this.doc === 'delivery' && this.docId) {
        await this.$store.dispatch('delivery/GetDeliveryById', this.docId).then((res) => {
          this.supplied = res.deliveryNoteItems;
        })
      } else if (this.doc === 'quotation' && this.docId) {
        await this.$store.dispatch('quotation/GetQuotationById', this.docId).then((res) => {
          this.supplied = res.quotationItems;
        })
      } else if (this.doc === 'order' && this.docId) {
        await this.$store.dispatch('order/GetOrderById', this.docId).then((res) => {
          this.supplied = res.orderItems;
        })
      } else if (this.doc === 'proforma' && this.docId) {
        await this.$store.dispatch('proforma/GetProformaById', this.docId).then((res) => {
          this.supplied = res.proformaInvoiceItems;
        })
      } else if (this.doc === 'invoice' && this.docId) {
        await this.$store.dispatch('invoice/GetInvoiceById', this.docId).then((res) => {
          this.supplied = res.invoiceItems;
        })
      } else if (this.doc === 'returnCertificate' && this.docId) {
        await this.$store.dispatch('returnCertificate/GetReturnCertificateById', this.docId).then((res) => {
          this.supplied = res.ReturnCertificateItems;
        })
      } else if (this.doc === 'purchaseOrder' && this.docId) {
        await this.$store.dispatch('purchaseOrder/GetPurchaseOrderById', this.docId).then((res) => {
          this.supplied = res.purchaseOrderItems;
        })
      } else if (this.doc === 'supplierDeliveryNote' && this.docId) {
        await this.$store.dispatch('supplierDeliveryNote/GetSupplierDeliveryNoteById', this.docId).then((res) => {
          this.supplied = res.supplierDeliveryNoteItems;
        })
      }
    },
    dialogChanged(newValue) {
      if (newValue) {
        this.shouldUpdateQty();
      }
    },
    async saveSupplied() {
      this.supplied.forEach(element => {
        delete element.same_id;
        delete element.document_number;
        delete element.document_date;
        delete element.customer_order_no;
        delete element.title;
        delete element.remainingQuantity;
      });
      this.supplied = this.supplied.filter(item => item.item_code !== "*")
      if (this.doc === 'delivery') {
        await this.$store.dispatch('delivery/EditDelivery', {delivery_id: this.docId, delivery: {data: this.supplied, main_number: 30, order_model_number: 15, quotation_model_number: 14}})
      } else if (this.doc === 'quotation') {
        if (this.supplied.every(ele => Number(ele.quantity) === Number(ele.supplied))) {
          await this.$store.dispatch('quotation/EditQuotation', {quotation_id: this.docId, quotation: {data: this.supplied, status: 1}})
          this.$emit('reload', 1)
        } else if (this.supplied.every(ele => Number(ele.quantity) !== Number(ele.supplied))) {
          await this.$store.dispatch('quotation/EditQuotation', {quotation_id: this.docId, quotation: {data: this.supplied, status: 0}})
          this.$emit('reload', 0)
        }
      } else if (this.doc === 'order') {
        if (this.supplied.every(ele => Number(ele.quantity) === Number(ele.supplied))) {
          await this.$store.dispatch('order/EditOrder', {order_id: this.docId, order: {data: this.supplied, main_number: 15, related_number: 14, status: 1}})
          this.$emit('reload', 1)
        } else if (this.supplied.every(ele => Number(ele.quantity) !== Number(ele.supplied))) {
          await this.$store.dispatch('order/EditOrder', {order_id: this.docId, order: {data: this.supplied, main_number: 15, related_number: 14, status: 0}})
          this.$emit('reload', 0)
        }
      } else if (this.doc === 'proforma') {
        await this.$store.dispatch('proforma/EditProforma', {proforma_id: this.docId, proforma: {data: this.supplied}})
      } else if (this.doc === 'invoice') {
        await this.$store.dispatch('invoice/EditInvoice', {invoice_id: this.docId, invoice: {data: this.supplied, main_number: 18, order_model_number: 15, deliveryNote_model_number: 30, quotation_model_number: 14}})
      } else if (this.doc === 'returnCertificate') {
        await this.$store.dispatch('returnCertificate/EditReturnCertificate', {tax_id: this.docId, delivery: {data: this.supplied, main_number: 118}})
      } else if (this.doc === 'purchaseOrder') {
        if (this.supplied.every(ele => Number(ele.quantity) === Number(ele.supplied))) {
          await this.$store.dispatch('purchaseOrder/EditPurchaseOrder', {purchase_order_id: this.docId, purchaseOrder: {data: this.supplied, main_number: 16, status: 1}})
          this.$emit('reload', 1)
        } else if (this.supplied.every(ele => Number(ele.quantity) !== Number(ele.supplied))) {
          await this.$store.dispatch('purchaseOrder/EditPurchaseOrder', {purchase_order_id: this.docId, purchaseOrder: {data: this.supplied, main_number: 16, status: 0}})
          this.$emit('reload', 0)
        }
      } else if (this.doc === 'supplierDeliveryNote') {
        await this.$store.dispatch('supplierDeliveryNote/EditSupplierDeliveryNote', {supplier_delivery_note_id: this.docId, supplierDeliveryNote: {data: this.supplied, main_number: 94}})
      } 
      this.$emit('update:dialog', false);
    }
  }
}
</script>